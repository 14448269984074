import util from 'util'

export function inspect(obj: any, colors = true): string {
  return util.inspect(obj, {
    maxArrayLength: null,
    colors,
    depth: null,
    showHidden: false,
  })
}
